import React from "react";
import Hero from "../../sections/templates/landing4/Hero";
import Feature from "../../sections/templates/landing4/Feature";
import Clients from "../../sections/templates/landing4/Clients";
import Content1 from "../../sections/templates/landing4/Content1";
import Content2 from "../../sections/templates/landing4/Content2"; //<-- migrate to a roadmap folder
import Fact from "../../sections/templates/landing4/Fact";
import PageWrapper from "../../components/PageWrapper";

const Roadmap = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Hero />
        <Feature />
        <Clients />
        <Content1 />
        <Content2 />
        <Content1 />
        <Fact />
      </PageWrapper>
    </>
  );
};
export default Roadmap;
